import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const ReservationCancelStyled = styled.div`
  display: flex;
  flex-direction: column;
`

export const ReservationCancelItem = styled.div<{ withMessage?: boolean }>`
  display: flex;

  ${({ withMessage }) =>
    withMessage &&
    css`
      flex-direction: column;
    `}

  .key {
    min-width: 70px;
  }

  .value {
    font-weight: bold;
    margin-left: 10px;
  }
`

export const BtnContainer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
`
