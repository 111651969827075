// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone'
import 'moment/locale/cs'

moment.locale(window.wpmlLanguage)

import {
  TimeSlot,
  Tarif,
  Reservation,
  Schedule,
  Extended,
  IPrice,
  Blocking,
  ReservationResponse,
} from '@facades/reservation'

export const currentDate: string = moment()
  .tz('Europe/Berlin')
  .format('YYYY-MM-DD')

export const formatDateToUser = (date: string) => {
  return moment(date, 'YYYY-MM-DD').format('MMMM DD, YYYY')
}

export const isStartDateValid = (startDate: string) =>
  moment
    .tz(startDate, 'YYYY-MM-DD', 'Europe/Berlin')
    .isAfter(moment.tz('Europe/Berlin'), 'day')

export const subtractTwoWeeks = (startDate: string) =>
  moment
    .tz(startDate, 'YYYY-MM-DD', 'Europe/Berlin')
    .subtract(14, 'days')
    .format('YYYY-MM-DD')

export const addTwoWeeks = (startDate: string) =>
  moment
    .tz(startDate, 'YYYY-MM-DD', 'Europe/Berlin')
    .add(14, 'days')
    .format('YYYY-MM-DD')

export const isStartDateWithinTwoMonths = (startDate: string) =>
  moment
    .tz(startDate, 'YYYY-MM-DD', 'Europe/Berlin')
    .isBefore(moment.tz('Europe/Berlin').add(2, 'months'), 'day')

interface GenerateTimeSlotsParams {
  startDate?: string
  numDays: number
  startTime?: string
  timeInterval?: string
  numTimeSlots?: number
  schedules: Schedule[]
}

export function generateTimeSlots({
  startDate = currentDate,
  numDays,
  schedules,
}: GenerateTimeSlotsParams): TimeSlot[][] {
  // Sort schedules by date
  schedules.sort(
    (a, b) =>
      moment(a.quest_book_quest_date).valueOf() -
      moment(b.quest_book_quest_date).valueOf()
  )

  const groupedTimeSlots: TimeSlot[][] = [[]]
  let lastScheduleId: number | null = null

  for (let day = 0; day < numDays; day++) {
    const current: moment.Moment = moment
      .tz(startDate, 'YYYY-MM-DD', 'Europe/Berlin')
      .add(day, 'days')
    const currentDateStr: string = current.format('YYYY-MM-DD')

    let schedule = schedules[0]
    // eslint-disable-next-line no-restricted-syntax
    for (const sch of schedules) {
      if (
        current.isSameOrAfter(
          moment
            .tz(
              sch.quest_book_quest_date,
              'YYYY-MM-DDTHH:mm:ss.SSSZ',
              'Europe/Berlin'
            )
            .format('YYYY-MM-DD')
        )
      ) {
        schedule = sch
      } else {
        break
      }
    }

    // Create a new group if the schedule changes
    if (lastScheduleId !== schedule.quest_book_id) {
      lastScheduleId = schedule.quest_book_id
      groupedTimeSlots.push([])
    }

    const timeArray: string[] = []
    let currentTime = moment.tz(
      schedule.quest_book_weekdays,
      'HH:mm:ss',
      'Europe/Berlin'
    )

    for (let i = 0; i < schedule.quest_book_nmb_sess_workday; i++) {
      timeArray.push(currentTime.format('HH:mm'))
      currentTime = currentTime.add(
        moment.duration(schedule.quest_book_game_duaration)
      )
      currentTime = currentTime.add(60, 'minutes')
    }

    groupedTimeSlots[groupedTimeSlots.length - 1].push({
      date: currentDateStr,
      time: timeArray,
      id: schedule.quest_book_id,
      extended: schedule.extended,
    })
  }

  return groupedTimeSlots
}

export function formatDateToReadableString(date: string) {
  return moment.tz(date, 'YYYY-MM-DD', 'Europe/Berlin').format('ddd D MMMM')
}

export function isWeekend(date: string): boolean {
  const dayOfWeek = moment.tz(date, 'YYYY-MM-DD', 'Europe/Berlin').day()
  return dayOfWeek === 5 || dayOfWeek === 6 || dayOfWeek === 0
}

export function getTarif(
  date: string,
  time: string,
  reservation: Reservation,
  blocking: Blocking,
  isAdminAvailable: boolean,
  tariffDays: ReservationResponse['tariffDays']
): Tarif {
  const dateTime = moment.tz(
    `${date} ${time}`,
    'YYYY-MM-DD HH:mm',
    'Europe/Berlin'
  )

  const currentTime = moment.tz('Europe/Berlin')
  const isBefore15 = dateTime.hour() < 15

  if (
    dateTime.isSameOrBefore(
      isAdminAvailable
        ? currentTime.add(15, 'minutes')
        : currentTime.add(2, 'hours')
    ) ||
    (reservation[date] && reservation[date][`${time}:00`]) ||
    (blocking[date] && blocking[date][time]) ||
    tariffDays[Tarif.Booked].includes(date)
  ) {
    return Tarif.Booked
  }

  for (const tariff of Object.values(Tarif)) {
    if (tariffDays[tariff].includes(date)) {
      return tariff
    }
  }

  if (!isWeekend(date)) {
    return isBefore15 ? Tarif.Low : Tarif.Medium
  }

  if (dateTime.day() === 5) {
    return isBefore15 ? Tarif.Low : Tarif.High
  }

  return Tarif.High
}

export const getPrice = (extended: Extended[], playersName: string): IPrice => {
  const playerExtended = extended.find(({ name }) => name === playersName)

  return {
    low: playerExtended?.low ?? null,
    medium: playerExtended?.medium ?? null,
    high: playerExtended?.high ?? null,
    booked: 'Booked',
  }
}
