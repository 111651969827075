import axios from 'axios'
import { Dispatch, SetStateAction } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { QueriesEnum } from '@facades/queries'
import { ICancelData } from '@facades/reservation'
import { decodeBase64 } from '@utils/decodeBase64'
import { getEndpoint } from '@utils/getEndpoint'
import { isBase64 } from '@utils/isBase64'

const cancelReservation = async (data: {
  data: string
  reason: string | null
}) => {
  if (!isBase64(data.data)) {
    throw new Error('Input is not a valid Base64-encoded string.')
  }

  const decoded = decodeBase64(data.data)

  let parsed
  try {
    parsed = JSON.parse(decoded)
  } catch (err) {
    throw new Error('Decoded data is not valid JSON.')
  }

  const confirmationData = { ...parsed, name: undefined, reason: data.reason }

  try {
    const response = await axios.post(
      `${getEndpoint()}/cancel_reservation`,
      confirmationData,
      {
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
      }
    )
    return response.data
  } catch (err) {
    console.error('Error during request:', err)
    throw err
  }
}

export const useCancelReservation = (
  setShowModal?: Dispatch<SetStateAction<ICancelData | boolean | number>>
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(cancelReservation, {
    onSuccess: (res) => {
      if (res.status !== 400) {
        queryClient.invalidateQueries(QueriesEnum.reservations)
        setShowModal?.(false)
      }
    },
    onError: (error: Error) => {
      console.error('Failed to make reservation:', error)
    },
  })

  const { isLoading } = mutation

  return {
    ...mutation,
    isLoading,
  }
}
